import React, { useEffect } from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Breadcrumb from "../components/Breadcrumb"
import ProductPage from "../components/ProductPage"
import CookiesConsent from "../components/CookiesConsent"
import { isBrowser } from "../helpers"

const Product = props => {
  useEffect(() => {
    if (!isBrowser) return

    let script = document.querySelector(
      `script[src="https://api.feefo.com/api/javascript/zest"]`
    )

    if (script) {
      script.parentNode.removeChild(script)
    }

    script = document.createElement("script")
    script.src = "https://api.feefo.com/api/javascript/zest"
    script.async = true
    script.type = "text/javascript"
    document.body.appendChild(script)

    return () => {
      if (script) {
        script.parentNode.removeChild(script)
      }
    }
  }, [])

  const getCategories = () => {
    let catStack = []

    if (props.data.wpProduct.productCategories !== null) {
      if (props.data.wpProduct.productCategories.nodes.length === 1) {
        catStack.push(props.data.wpProduct.productCategories.nodes[0])
      } else {
        props.data.wpProduct.productCategories.nodes.forEach(category => {
          if (category.ancestors !== null) {
            if (category.ancestors.nodes.length >= catStack.length) {
              catStack = [
                {
                  id: category.id,
                  name: category.name,
                  slug: category.slug,
                  uri: category.uri,
                },
              ]
              category.ancestors.nodes.forEach(ancestor => {
                catStack.push(ancestor)
              })
            }
          }
        })
      }
    }

    if (catStack.length > 1) {
      catStack.reverse()
    }

    return catStack
  }

  const cats = getCategories()

  return (
    <Layout
      meta={{
        ...props.data.wpProduct.Meta,
        ogImage: props.data.wpProduct.ProductData.featuredImage,
        twitterImage: props.data.wpProduct.ProductData.featuredImage,
        canonical: props.data.wpProduct.uri,
      }}
      path={props.pageContext.pagePath}
      cta={props.data.wpProduct.PageCTA}
      themeOptions={props.data.wp.themeOptions}
    >
      <Breadcrumb
        type="product"
        current={{
          title: props.data.wpProduct.title,
          slug: props.data.wpProduct.slug,
          uri: props.data.wpProduct.uri,
        }}
        ancestors={cats}
      />

      <ProductPage
        categories={cats}
        productData={props.data.wpProduct.ProductData}
        productMeta={props.data.wpProduct.ProductMeta}
        uri={props.data.wpProduct.uri}
        params={props.location.search}
      />
      <CookiesConsent />
    </Layout>
  )
}

export const query = graphql`
  query($id: String) {
    wpProduct(id: { eq: $id }, status: { eq: "publish" }) {
      id
      slug
      title
      uri
      databaseId
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
      }
      ProductData {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              formats: [AUTO, WEBP]
              transformOptions: { fit: COVER, cropFocus: CENTER }
            )
          }
        }
        productIdBase64
        comparePrice
        description
        featuredImage
        options
        price
        images {
          image
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
          variants {
            variantSku
          }
        }
        media {
          mediaItemUsdz
          mediaItemGlb
          mediaItemPreview
        }
        title
        variants {
          inStock
          comparePrice
          image
          option1
          option2
          option3
          price
          quantity
          sku
          title
          weight
          variantIdBase64
        }
      }
      ProductMeta {
        assemblyTime
        assemblyDifficulty
        availableToConsumers
        availableToTrade
        bestseller
        careAndMaintenance
        delivery
        dimensions
        discontinued
        certification
        madeInBritain
        materials
        personsToAssemble
        shortDescription
        shippingBoxes
        preservativeTreatment
        warrantyPeriod
        videos {
          url
        }
        pdfs {
          url
        }
        features {
          text
        }
        matchingAccessories {
          ... on WpProduct {
            id
            slug
            title
            link
            ProductData {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              comparePrice
              description
              featuredImage
              options
              price
              images {
                image
                altText
              }
              title
              variants {
                inStock
                comparePrice
                image
                option1
                option2
                option3
                price
                quantity
                sku
                title
                weight
                variantIdBase64
              }
            }
          }
        }
        matchingItems {
          ... on WpProduct {
            id
            slug
            title
            link
            ProductData {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              comparePrice
              description
              featuredImage
              options
              price
              images {
                image
                altText
              }
              title
              variants {
                inStock
                comparePrice
                image
                option1
                option2
                option3
                price
                quantity
                sku
                title
                weight
                variantIdBase64
              }
            }
          }
        }
        relatedProducts {
          ... on WpProduct {
            id
            slug
            title
            link
            ProductData {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              comparePrice
              description
              featuredImage
              options
              price
              images {
                image
                altText
              }
              title
              variants {
                inStock
                comparePrice
                image
                option1
                option2
                option3
                price
                quantity
                sku
                title
                weight
                variantIdBase64
              }
            }
          }
        }
        containsProducts {
          ... on WpProduct {
            id
            slug
            title
            link
            ProductData {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    transformOptions: { fit: COVER, cropFocus: CENTER }
                  )
                }
              }
              title
              variants {
                sku
                title
                weight
              }
            }
            ProductMeta {
              materials
              dimensions
            }
          }
        }
      }
      productCategories {
        nodes {
          id
          name
          slug
          uri
          ancestors {
            nodes {
              id
              name
              slug
              uri
            }
          }
        }
      }
    }
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
  }
`

export default Product
