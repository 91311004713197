import React, { Component } from "react"

import Slider from "react-slick"

import ArrowSvg from "../../ArrowSvg"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import ModelViewer, { ModelViewerPreview } from "../ModelViewer"

import ZoomedImage from "./ZoomedImage"
import PreviewImage from "../../PreviewImage"

export default class GallerySlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      nav1: null,
      nav2: null,
      slider: [],
      image: null,
      posX: null,
      poxY: null,
      offsetX: null,
      offsetY: null,
      isModelViewer: false,
      imageSelectedBySliderClick: false,
      previousVariant: false,
    }

    this.sliderRef = React.createRef()
    this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this)
    this.onMouseOutHandler = this.onMouseOutHandler.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidMount() {
    this.setState({
      slider: this.props.images,
      nav1: this.slider1,
      nav2: this.slider2,
    })
  }

  handleOnHover(e) {
    this.setState({
      offsetX:
        ((e.clientX - this.sliderRef.current.getBoundingClientRect().left) /
          this.sliderRef.current.getBoundingClientRect().width) *
        100,
      offsetY:
        ((e.clientY - this.sliderRef.current.getBoundingClientRect().top) /
          this.sliderRef.current.getBoundingClientRect().height) *
        100,
    })
  }

  onMouseEnterHandler(e, image, isModelViewer) {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    if (windowWidth <= 960) return

    const bounds = this.sliderRef.current.getBoundingClientRect()
    this.setState({
      image,
      width: bounds.width,
      posX: bounds.x + bounds.width,
      posY: bounds.y + bounds.height,
      isModelViewer,
    })
  }

  onMouseOutHandler() {
    const windowWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth

    if (windowWidth < 960) return

    this.closeModal()
  }

  onMouseClickHandler(e, image) {
    this.setState({
      image,
      width: "100%",
      posX: 0,
      posY: 0,
    })
  }

  closeModal() {
    this.setState({ image: null })
  }

  changeSlide(current) {
    this.setState({ imageSelectedBySliderClick: true })
  }

  static getDerivedStateFromProps(props, state) {
    // Any time the variant changes, reset the imageSelectedBySliderClick in the state
    if (props.variant !== state.previousVariant) {
      return {
        previousVariant: props.variant,
        imageSelectedBySliderClick: false,
      }
    }
    return null
  }

  render() {
    const { slider } = this.state
    const { media } = this.props

    const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
      <span
        {...props}
        aria-hidden="true"
        aria-disabled={currentSlide === 0 ? true : false}
        type="button"
      >
        <ArrowSvg />
      </span>
    )
    const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
      <span
        {...props}
        aria-hidden="true"
        aria-disabled={currentSlide === slideCount - 1 ? true : false}
        type="button"
      >
        <ArrowSvg />
      </span>
    )

    if (!slider) return null

    const slides = slider.length > 2 ? slider.slice(0, 2) : slider
    const slidesCont = slider.length > 2 ? slider.slice(2) : null

    if (this.props.variant && this.state.imageSelectedBySliderClick === false) {
      slider.map((slide, slideIndex) => {
        if (slide.variants && slide.variants.length) {
          slide.variants.map(variant => {
            if (this.props.variant === variant.variantSku) {
              this.slider1.slickGoTo(slideIndex + (media ? media.length : 0))
            }
          })
        }
      })
    }

    return (
      <div>
        <div ref={this.sliderRef}>
          <Slider
            ref={slider => (this.slider2 = slider)}
            asNavFor={this.state.nav1}
            dots={false}
            arrows={false}
            touchMove={!this.state.isModelViewer}
            className="slider-main"
          >
            {slides?.map((image, index) => {
              return (
                <button
                  className="slide-image"
                  key={"image-" + index}
                  onMouseEnter={e => this.onMouseEnterHandler(e, image.image)}
                  onMouseOut={() => this.onMouseOutHandler()}
                  onBlur={() => this.onMouseOutHandler()}
                  onMouseMove={e => {
                    this.handleOnHover(e)
                  }}
                  onClick={e => this.onMouseClickHandler(e, image.image)}
                >
                  <PreviewImage
                    image={image.localFile}
                    alt={image.altText}
                    fallbackURL={image.localFile.publicURL}
                    fixedPadding={`100%`}
                  />
                </button>
              )
            })}
            {media?.map((item, index) => {
              return (
                <button
                  key={`model3d-${index}`}
                  className="slide-image"
                  onMouseEnter={e =>
                    this.setState({
                      isModelViewer: true,
                    })
                  }
                >
                  <ModelViewer media={item} view3dRef={this.props.view3dRef} />
                </button>
              )
            })}
            {slidesCont?.map((image, index) => {
              return (
                <button
                  className="slide-image"
                  key={"image-" + index}
                  onMouseEnter={e => this.onMouseEnterHandler(e, image.image)}
                  onMouseOut={() => this.onMouseOutHandler()}
                  onBlur={() => this.onMouseOutHandler()}
                  onMouseMove={e => {
                    this.handleOnHover(e)
                  }}
                  onClick={e => this.onMouseClickHandler(e, image.image)}
                >
                  <PreviewImage
                    image={image.localFile}
                    alt={image.altText}
                    fallbackURL={image.localFile.publicURL}
                    fixedPadding={`100%`}
                  />
                </button>
              )
            })}
          </Slider>
        </div>
        <Slider
          ref={slider => (this.slider1 = slider)}
          asNavFor={this.state.nav2}
          dots={false}
          arrows={true}
          nextArrow={<SlickArrowRight />}
          prevArrow={<SlickArrowLeft />}
          infinite={false}
          slidesToShow={3}
          swipeToSlide={true}
          focusOnSelect={true}
          className={`slider-nav`}
          afterChange={current => this.changeSlide(current)}
        >
          {slides?.map((image, index) => {
            return (
              <div className="slide-image" key={"nav-image-" + index}>
                <PreviewImage
                  image={image.localFile}
                  alt={image.altText}
                  fallbackURL={image.localFile.publicURL}
                  fixedPadding="100%"
                />
              </div>
            )
          })}
          {media?.map((item, index) => {
            return (
              <div key={`model3d-${index}`} className="slide-image">
                <ModelViewerPreview media={item} />
              </div>
            )
          })}
          {slidesCont?.map((image, index) => {
            return (
              <div className="slide-image" key={"nav-image-" + index}>
                <PreviewImage
                  image={image.localFile}
                  alt={image.altText}
                  fallbackURL={image.localFile.publicURL}
                  fixedPadding="100%"
                />
              </div>
            )
          })}
        </Slider>
        <>
          <ZoomedImage
            image={this.state.image}
            posX={this.state.posX}
            offsetX={this.state.offsetX}
            posY={this.state.posY}
            offsetY={this.state.offsetY}
            width={this.state.width}
            height={this.state.height}
            closeModal={() => this.closeModal()}
          />
        </>
      </div>
    )
  }
}
