import { Link } from "gatsby"
import React from "react"

export default function StatusMessages({ status, quantity }) {
  return (
    <>
      {status === "cart-success" && (
        <div className="cart-action">
          <Link to="/customer/basket/">{`Successfully added ${quantity} ${
            quantity === 1 ? "item" : "items"
          } to your cart`}</Link>
        </div>
      )}

      {status === "wishlist-remove-success" && (
        <div className="cart-action">
          Successfully removed items from your wishlist
        </div>
      )}

      {status === "cart-error" && (
        <div className="cart-action error">
          There was an error adding your item to cart
        </div>
      )}

      {status === "wishlist-success" && (
        <div className="cart-action">
          {`Successfully added ${quantity} ${
            quantity === 1 ? "item" : "items"
          } to your wishlist`}
        </div>
      )}

      {status === "wishlist-error" && (
        <div className="cart-action error">
          There was an error adding your item to wishlist
        </div>
      )}
    </>
  )
}
