import React from "react"

export default function Availability({ quantity, discontinued }) {
  if (discontinued) {
    return <p className="discontinued">This item has been discontinued</p>
  }

  if (quantity >= 50) {
    return <p className="in-stock">In Stock</p>
  }

  if (quantity > 0) {
    return <p className="in-stock">Last few remaining</p>
  }

  return null
}
