import React from "react"

export default function Quantity({
  quantity,
  decrementQuantity,
  incrementQuantity,
  setQuantity,
}) {
  return (
    <div>
      Quantity
      <div className="quantity-input">
        <button
          onClick={e => {
            decrementQuantity(e)
          }}
        >
          -
        </button>
        <input
          type="text"
          value={quantity}
          onChange={e => setQuantity(Number(e.target.value))}
        ></input>
        <button
          onClick={e => {
            incrementQuantity(e)
          }}
        >
          +
        </button>
      </div>
    </div>
  )
}
