import React from "react"

export default function Prices({ price, rprPrice }) {
  return (
    <p className="prices">
      {price > 0 && <span className="zest-price">£{price.toFixed(2)}</span>}
      {rprPrice > 0 && <span className="rrp-label"> RRP</span>}
      {rprPrice > 0 && (
        <span className="rrp-value">£{rprPrice.toFixed(2)}</span>
      )}
    </p>
  )
}
