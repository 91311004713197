import React, { useRef } from "react"
import styles from "./styles"
import { makeStyles } from "@material-ui/styles"
import GallerySlider from "./slider"
import { ArView } from "../ModelViewer"

export default function ProductImageSlider({ images, media, title, variant }) {
  const useStyles = makeStyles(theme => styles(theme))
  const classes = useStyles()
  const view3dRef = useRef(null)

  return (
    <div className={classes.productImageSlider}>
      <GallerySlider images={images} media={media} view3dRef={view3dRef} title={title} variant={variant} />
      <ArView media={media} view3dRef={view3dRef} />
    </div>
  )
}
