import React from "react"
import { makeStyles } from "@material-ui/styles"

import styles from "./ZoomedImage.styles"

export default function ZoomedImage({
  image,
  posX,
  offsetX,
  posY,
  offsetY,
  closeModal,
}) {
  const useStyles = makeStyles(theme => styles(theme))
  const classes = useStyles()

  if (!image) return null

  const windowWidth =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth

  if (windowWidth < 960) {
    return (
      <div
        className={classes.ZoomedImageMobile}
        style={{
          backgroundImage: `url(${image})`,
        }}
      >
        <button
          onClick={() => closeModal()}
          style={{
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          Back
        </button>
      </div>
    )
  }

  return (
    <div
      className={classes.ZoomedImageDesktop}
      style={{
        width: windowWidth - posX - 40,
        backgroundImage: `url(${image})`,
        backgroundPositionX: `${offsetX}%`,
        backgroundPositionY: `${offsetY}%`,
      }}
    ></div>
  )
}
