const styles = theme => ({
  ZoomedImageMobile: {
    position: "fixed",
    top: 0,
    left: 0,
    padding: "12px",
    paddingBottom: "100%",
    width: "100%",
    height: "100%",
    zIndex: 10,
    backgroundSize: "contain",
    backgroundPositionY: "center",
    backgroundColor: "white",
    backgroundRepeat: "no-repeat",
  },
  ZoomedImageDesktop: {
    position: "absolute",
    top: 0,
    left: "100%",
    width: "calc(50vw + 8.333%) !important",
    padding: "12px",
    aspectRatio: 1.33,
    backgroundSize: "200%",
  },
})

export default styles
