const styles = theme => ({
  product: {
    backgroundColor: theme.common.white,
    paddingTop: 20,
    [theme.breakpoints.up("md")]: {
      paddingTop: 44,
    },
    "& > h2": {
      display: "block",
      position: "relative",
      paddingTop: 32,
      marginTop: 0,
      maxWidth: 1142,
      width: "100%",
      marginLeft: "auto",
      marginRight: "auto",
      fontFamily: theme.headerFont,
      padding: "0 12px",
      color: theme.palette.primary.navy,
      fontSize: 28,
      lineHeight: 1.25,
      marginBottom: 12,
      fontWeight: "bold",
      letterSpacing: "-0.008em",
      [theme.breakpoints.up("lg")]: {
        fontSize: 32,
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: 72,
      },
    },
    "& .slider-controls": {
      "& .buttons": {
        marginTop: 32,
        marginBottom: 24,
        flex: "0 0 100%",
        padding: "0 12px",
        "& button": {
          padding: "16px 32px",
          fontSize: 16,
          fontWeight: "bold",
          color: theme.palette.primary.purple,
          borderRadius: 4,
          border: "none",
          backgroundColor: "transparent",
          boxShadow: theme.boxShadow,
          lineHeight: 1,
          '&[data-active="true"]': {
            color: theme.palette.secondary.cream,
            backgroundColor: theme.palette.primary.purple,
          },
        },
      },
    },
  },
})

export default styles
