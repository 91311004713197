const styles = theme => ({
  productVariants: {
    flex: "0 0 100%",
    maxWidth: "100%",
    padding: 12,
    [theme.breakpoints.up("md")]: {
      flex: "0 0 50%",
      maxWidth: "50%",
    },
    "& .prod-data-top": {
      "& .review-button": {
        display: "block",
        padding: 0,
        border: 0,
        margin: 0,
        fontSize: 0,
        background: "none",
        marginBottom: 10,
        float: "right",
        marginLeft: 10,
      },
      "& .bestseller-tag": {
        fontSize: 8,
        textTransform: "uppercase",
        letterSpacing: "0.152em",
        lineHeight: 1.25,
        padding: "8px 10px",
        fontWeight: "bold",
        borderRadius: 4,
        backgroundColor: theme.palette.tertiary.light,
        color: theme.palette.primary.navy,
        display: "table",
        marginBottom: 24,
      },
      "& .sku": {
        display: "block",
        fontSize: 12,
        fontWeight: "bold",
        color: theme.palette.secondary.beige,
        letterSpacing: "0.152em",
        lineHeight: 1.25,
        marginBottom: 16,
        marginTop: 0,
      },
      "& h1": {
        fontFamily: theme.headerFont,
        color: theme.palette.primary.purple,
        fontSize: 24,
        letterSpacing: "0.008em",
        lineHeight: 1.33,
        display: "block",
        marginBottom: 0,
        marginTop: 0,
        fontWeight: "bold",
      },
    },
    "& .prices": {
      marginTop: 5,
      lineHeight: 1,
      fontSize: 24,
      color: theme.palette.primary.purple,
      fontWeight: "bold",
      marginBottom: 16,
      "& .rrp-label": {
        display: "inline-block",
        verticalAlign: "bottom",
        marginLeft: 12,
        lineHeight: 1,
        fontSize: 12,
        fontWeight: "bold",
        letterSpacing: "0.152em",
        color: theme.palette.secondary.beige,
      },
      "& .rrp-value": {
        display: "inline-block",
        verticalAlign: "bottom",
        lineHeight: 1,
        fontSize: 16,
        fontWeight: "normal",
        letterSpacing: "0",
        marginLeft: 4,
        textDecoration: "line-through",
        color: theme.palette.secondary.beige,
      },
    },
    "& .variant-description": {
      fontSize: 14,
      letterSpacing: "0.016em",
      color: theme.palette.primary.purple,
      marginTop: 0,
      marginBottom: 24,
      lineHeight: 1.8,
      "& a": {
        color: "#009f4d",
        fontWeight: "bold",
        textDecoration: "underline",
      },
    },
    "& .key-breakdown": {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "space-between",
      "& div": {
        flex: "0 0 100%",
        display: "flex",
        color: theme.palette.primary.purple,
        fontSize: 14,
        lineHeight: 1.8,
        letterSpacing: 0,
        alignItems: "flex-start",
        marginBottom: 16,
        [theme.breakpoints.up("sm")]: {
          flex: "0 0 calc(50% - 12px)",
        },
        "& img": {
          maxWidth: 24,
          marginTop: 6,
          flex: "0 0 24px",
          marginRight: 12,
        },
        "& strong": {
          display: "block",
        },
        "& button": {
          fontWeight: "bold",
          color: "#00313C",
          fontSize: 14,
          border: "none",
          textDecoration: "underline",
          backgroundColor: "transparent",
          padding: 0,
        },
      },
    },

    "& .product-options": {
      "& .select-inputs": {
        display: "flex",
        flexWrap: "nowrap",
        flexDirection: "column",
        [theme.breakpoints.up("sm")]: {
          flexDirection: "row",
        },
        "& label": {
          fontSize: 12,
          letterSpacing: "0.152em",
          textTransform: "uppercase",
          fontWeight: "bold",
          color: "#484545",
          [theme.breakpoints.up("sm")]: {
            flex: "0 0 33.333%",
            maxWidth: "33.333%",
            paddingRight: 24,
          },
        },
        "& select": {
          fontSize: 16,
          color: "#333",
          lineHeight: 1.5,
          width: "100%",
          border: "none",
          padding: "12px 16px",
          outline: "none",
          transition: "all .3s ease-in-out",
          appearance: "none",
          margin: 0,
          marginBottom: 24,
          borderRadius: 8,
          fontFamily: theme.bodyFont,
          backgroundImage:
            "linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%)",
          backgroundPosition: "calc(100% - 23px) 20px, calc(100% - 15px) 20px",
          backgroundSize: "9px 6px, 9px 6px",
          backgroundRepeat: "no-repeat",
          marginTop: 4,
          "& option": {
            backgroundColor: "white !important",
            color: theme.common.black + " !important",
          },
          "&:focus": {
            outline: "none",
            backgroundColor: "rgba(0,0,0,0.05) !important",
            "& ~ .after": {
              backgroundPosition: "0 0 !important",
            },
          },
        },
      },
      "& .sku-and-stock": {
        display: "flex",
        alignItems: "flex-end",
        paddingLeft: 90,
        marginBottom: -47,
        marginTop: 32,
        "& p": {
          margin: 0,
          "&.sku": {
            fontWeight: 900,
            letterSpacing: "0.152em",
            lineHeight: 1,
            marginRight: 24,
            "& span": {
              display: "block",
              letterSpacing: "0.152em",
              fontSize: 12,
              lineHeight: 1,
              fontWeight: "normal",
            },
          },
          "&.in-stock": {
            fontWeight: 900,
            color: "#2E8B1D",
            lineHeight: 1,
            "&::before": {
              content: '""',
              display: "inline-block",
              width: 11,
              height: 11,
              borderRadius: "50%",
              marginRight: 5,
              letterSpacing: "0.016em",
              backgroundColor: "#2E8B1D",
            },
          },
          "&.processing": {
            fontWeight: 900,
            color: "#ddca42",
            lineHeight: 1,
            "&::before": {
              content: '""',
              display: "inline-block",
              width: 11,
              height: 11,
              borderRadius: "50%",
              marginRight: 5,
              letterSpacing: "0.016em",
              backgroundColor: "#ddca42",
            },
          },
        },
      },
    },
    "& .cart-options": {
      marginTop: 32,
      display: "flex",
      flexWrap: "wrap",
      alignItems: "flex-end",
      marginBottom: 24,
      "& div": {
        marginRight: 24,
        fontSize: 12,
        letterSpacing: "0.152em",
        textTransform: "uppercase",
        color: "#484545",
        fontWeight: "bold",
        display: "block",
        width: "100%",
        [theme.breakpoints.up("lg")]: {
          width: 130,
        },
        "& .quantity-input": {
          display: "flex",
          width: 130,
          marginTop: 24,
          alignItems: "center",
          backgroundColor: "#fff",
          "& button, & input": {
            height: 50,
            fontSize: 16,
            fontWeight: 900,
            textAlign: "center",
            borderRadius: 0,
            lineHeight: "50px",
          },
          "& button": {
            border: "1px solid #b1a9a0",
            flex: "0 0 32px",
            width: 32,
            color: "rgba(0,0,0,0.35)",
            padding: 0,
            lineHeight: "44px",
            backgroundColor: "transparent",
            "&:nth-of-type(1)": {
              borderRight: "none",
              borderBottomLeftRadius: 8,
              borderTopLeftRadius: 8,
            },
            "&:nth-of-type(2)": {
              borderLeft: "none",
              borderBottomRightRadius: 8,
              borderTopRightRadius: 8,
            },
          },
          "& input": {
            flex: "0 0 66px",
            backgroundColor: "#fff",
            width: 66,
            border: "none",
            color: theme.palette.primary.navy,
            borderTop: "1px solid #b1a9a0",
            borderBottom: "1px solid #b1a9a0",
          },
        },
      },
      "& button.add-button": {
        backgroundColor: theme.palette.primary.green,
        border: "none",
        padding: "15px 24px",
        color: theme.palette.primary.navy,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1,
        borderRadius: 4,
        marginRight: 24,
        boxShadow: "0 8px 16px rgba(0, 49, 60, .16)",
        marginTop: 12,
        transition: "all .3s ease-in-out",
        "& svg": {
          verticalAlign: "text-bottom",
          marginLeft: 12,
          "& #Basket-Icon": {
            transition: "all .3s ease-in-out",
          },
        },
        "&:hover, &:focus": {
          color: theme.palette.primary.green,
          backgroundColor: theme.palette.primary.navy,
          "& svg": {
            "& #Basket-Icon": { fill: theme.palette.primary.green },
          },
        },
        "&.disabled": {
          color: "darkgray",
          backgroundColor: "lightgray",

          "& svg": {
            "& #Basket-Icon": { fill: "darkgray" },
          },

          "&:hover, &:focus": {
            color: "darkgray",
            backgroundColor: "lightgray",
            "& svg": {
              "& #Basket-Icon": { fill: "darkgray" },
            },
          },
        },
      },
      "& button.wishlist": {
        width: 50,
        marginTop: 12,
        height: 50,
        fontSize: 0,
        borderRadius: "50%",
        backgorundColor: "transparent",
        border: "2px solid " + theme.palette.primary.navy,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& svg": {
          width: 24,
          height: 24,
          "& path": {
            fill: "transparent",
            stroke: theme.palette.primary.navy,
            strokeWidth: 2,
          },
        },
        "&.added": {
          backgroundColor: theme.palette.primary.navy,
          "& svg": {
            "& path": {
              fill: theme.common.white,
            },
          },
        },
      },
    },
    "& a.find-store": {
      color: theme.palette.primary.navy,
      fontSize: 14,
      textDecoration: "underline !important",
      fontWeight: "bold",
    },
    "& .find-stockist": {
      padding: "25px 30px",
      border: "1px solid #b1a9a0",
      borderRadius: 8,
      marginBottom: 32,
      backgroundColor: "#fff",
      "& h4": {
        fontFamily: theme.bodyFont,
        color: theme.palette.primary.purple,
        fontSize: 16,
        lineHeight: 1.5,
        letterSpacing: "0.008em",
        marginTop: 0,
        "& svg": {
          display: "inline-block",
          verticalAlign: "middle",
          marginRight: 8,
          top: -2,
          position: "relative",
        },
      },
      "& p": {
        fontSize: 14,
        lineHeight: 1.75,
        letterSpacing: "0.016em",
        color: theme.palette.primary.purple,
        marginTop: 0,
        marginBottom: 24,
      },
      "& a": {
        backgroundColor: theme.palette.primary.green,
        border: "none",
        padding: "15px 24px",
        color: theme.palette.primary.navy,
        fontWeight: "bold",
        fontSize: 18,
        lineHeight: 1,
        borderRadius: 4,
        marginRight: 24,
        boxShadow: "0 8px 16px rgba(0, 49, 60, .16)",
        display: "table",
        "& svg": {
          verticalAlign: "text-bottom",
          marginLeft: 12,
        },
      },
    },
    "& .cart-action": {
      position: "fixed",
      bottom: 12,
      right: 12,
      borderRadius: 4,
      padding: "12px 16px",
      backgroundColor: theme.palette.primary.navy,
      color: theme.palette.secondary.cream,
      fontSize: 12,
      letterSpacing: "0.016em",
      boxShadow: theme.shadow,
      zIndex: 1000,
      "& a": {
        color: "inherit",
      },
      "& .error": {
        backgroundColor: "darkred",
      },
    },
  },
})
export default styles
