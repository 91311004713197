const styles = theme => ({
  productImageSlider: {
    position: "relative",
    flex: "0 0 100%",
    maxWidth: "100%",
    padding: 12,
    [theme.breakpoints.up("md")]: {
      flex: "0 0 50%",
      maxWidth: "50%",
    },
    [theme.breakpoints.up("lg")]: {
      flex: "0 0 41.666%",
      maxWidth: "41.666%",
      marginRight: "8.333%",
    },
    "& .slider-main": {},
    "& .slide-image": {
      padding: 0,
      border: "none",
      background: "transparent",
      borderRadius: 4,
      transition: "background-color .3s ease-in-out",
      overflow: "hidden",
      cursor: "pointer",
      "& img": {
        width: "100%",
        borderRadius: 4,
        aspectRatio: 1,
        objectFit: "contain",
      },
    },
    "& .slider-nav": {
      width: "100%",
      "& .slick-list": {
        margin: "0 0",
      },
      "& .slick-slide": {
        fontSize: 0,
        padding: 12,
        "& .slide-image": {
          display: "block",
        },
      },
      "& .slick-arrow": {
        margin: 12,
        width: 28,
        height: 28,
        flex: "0 0 28px",
        zIndex: 4,
        [theme.breakpoints.up("md")]: {
          width: 54,
          height: 54,
          flex: "0 0 54px",
        },
        "&::before": {
          display: "none",
        },
        "& svg": {
          "& .chev": { fill: theme.palette.primary.green },
          "& .circ": { fill: theme.palette.primary.navy },
        },
        "&.slick-prev": {
          "& svg": {
            transform: "scaleX(-1)",
          },
        },
        "&.slick-disabled": {
          "& svg": {
            "& .chev": { fill: theme.palette.primary.navy },
            "& .circ": {
              fill: theme.palette.primary.green,
              stroke: theme.palette.primary.navy,
              strokeWidth: 2,
            },
          },
        },
      },
    },
  },
})

export default styles
