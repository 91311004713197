const styles = theme => ({
  infoAndSpec: {
    marginTop: 32,
    [theme.breakpoints.up("md")]: {
      marginTop: 72,
    },
    "& .tab-nav": {
      "& .wrapper": {
        padding: "0",
        height: 40,
        overflow: "hidden",
        [theme.breakpoints.up("md")]: {
          height: 48,
        },
        "& .tab-wrap": {
          paddingLeft: 12,
          width: "100vw",
          whiteSpace: "nowrap",
          overflowX: "scroll",
          height: 80,
        },
        "& button": {
          padding: "12px",
          fontSize: 16,
          fontWeight: "bold",
          color: theme.palette.primary.purple,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          border: "none",
          backgroundColor: "transparent",
          lineHeight: 1,
          [theme.breakpoints.up("md")]: {
            padding: "16px 32px",
          },
          "&.active": {
            color: theme.palette.secondary.cream,
            backgroundColor: theme.palette.primary.purple,
          },
        },
      },
    },
    "& .tabs": {
      backgroundColor: theme.palette.primary.purple,
      borderRadius: 4,
      padding: "32px 0",
      [theme.breakpoints.up("md")]: {
        padding: "64px 0",
      },
      "& .overview, & .features, & .product-sepc, & .assembly-care, & .video-docs": {
        padding: "0 12px",
        flex: "0 0 100%",
        maxWidth: "100%",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 41.666%",
          marginRight: "8.333%",
        },
        "& h3": {
          color: theme.palette.secondary.cream,
          fontSize: 24,
          fontWeight: "bold",
          letterSpacing: "0.008em",
          lineHeight: 1.25,
          marginBottom: 24,
        },
        "& p": {
          color: theme.palette.secondary.cream,
          marginBottom: 32,
          fontSize: 16,
          letterSpacing: "0.016em",
          lineHeight: 2,
          "& a": {
            color: theme.palette.primary.green,
            fontWeight: "bold",
            textDecoration: "none",
            "&:hover, &:focus": {
              textDecoration: "underline",
            },
          },
        },
        "& ul": {
          "& li": {
            fontSize: 16,
            color: theme.palette.primary.green,
            lineHeight: 1.5,
            marginBottom: 12,
            "& span": {
              color: theme.palette.secondary.cream,
            },
          },
        },
        "& table": {
          width: "100%",
          display: "inline-table",
          borderCollapse: "collapse",
          marginBottom: 32,
          [theme.breakpoints.up("md")]: {
            width: "41.666%",
            marginRight: "8.333%",
          },
          "& th": {
            display: "block",
            "& td": {
              fontSize: 16,
              letterSpacing: 0,
              color: theme.palette.secondary.cream,
              padding: "20px 0",
              fontWeight: "bold",
            },
          },
          "& tr": {
            backgroundColor: "rgba(255,255,255,.3)",
            borderRadius: 4,
            border: 0,
            marginBottom: 2,
            display: "block",
            "&:nth-of-type(even)": {
              backgroundColor: "rgba(255,255,255,.05)",
            },
            "& td": {
              fontSize: 16,
              letterSpacing: 0,
              color: theme.palette.secondary.cream,
              padding: "20px 30px",
              "&:first-child": {
                width: 150,
              },
            },
          },
        },
        "& .icons": {
          display: "flex",
          maxWidth: 340,
          flexWrap: "no-wrap",
          justifyContent: "space-between",
          "& span": {
            display: "block",
            color: theme.palette.secondary.cream,
            fontSize: 14,
            fontWeight: "bold",
            lineHeight: "16px",
            marginBottom: 32,
            textAlign: "center",
            maxWidth: 85,
            "& img": {
              width: 50,
              display: "block",
              marginBottom: 12,
              marginLeft: "auto",
              marginRight: "auto",
            },
          },
        },
        "& .video": {
          padding: 24,
          backgroundColor: theme.palette.secondary.beige,
          borderRadius: 4,
          "& .res-video": {
            position: "relative",
            height: 0,
            marginBottom: "1rem",
            overflow: "hidden",
            paddingBottom: "56.25%",
            "& iframe": {
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              border: "none",
            },
          },
          "& .actions": {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
            flexWrap: "wrap",
            "& button": {
              backgroundColor: "transparent",
              border: "none",
              padding: 0,
              fontSize: 16,
              fontWeight: "bold",
              letterSpacing: "0.016em",
              color: theme.palette.primary.navy,
              textAlign: "left",
              "& svg": {
                marginLeft: 12,
                "& path": {
                  fill: "transparent",
                  stroke: theme.palette.primary.navy,
                },
              },
              '&[data-active="true"]': {
                color: theme.common.white,
                "&::before": {
                  content: '"Playing"',
                  fontSize: 12,
                  color: theme.palette.primary.purple,
                  letterSpacing: "0.152em",
                  textTransform: "uppercase",
                  display: "block",
                  margin: 0,
                  marginBottom: 4,
                  lineHeight: 1,
                },
                "& svg": {
                  "& path": {
                    stroke: theme.common.white,
                  },
                },
              },
            },
          },
        },
        "& .pdfs": {
          marginTop: -4,
          backgroundColor: "rgba(255,255,255,0.05)",
          padding: 30,
          borderRadius: 4,
          "& h3": {
            marginTop: 0,
            float: "left",
            marginBottom: 24,
            width: "100%",
            [theme.breakpoints.up("md")]: {
              width: "auto",
            },
          },
          "& a": {
            color: theme.palette.primary.green,
            fontWeight: "bold",
            letterSpacing: "0.016em",
            fontSize: 16,
            padding: 16,
            display: "block",
            width: 250,
            marginBottom: 4,
            marginLeft: "auto",
            marginRight: 0,
            "& svg": {
              display: "inline-block",
              verticalAlign: "text-bottom",
              marginRight: 12,
            },
          },
        },
      },
      "& div.video-docs": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 50%",
          marginRight: 0,
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 50%",
          marginRight: 0,
        },
      },
      "& div.product-sepc": {
        [theme.breakpoints.up("md")]: {
          flex: "0 0 100%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 100%",
        },
      },
      "& div.star-col": {
        flex: "0 0 100%",
        padding: "0 12px",
        textAlign: "left",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 33.333%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 50%",
        },
      },
      "& div.review-col": {
        flex: "0 0 100%",
        padding: "0 12px",
        [theme.breakpoints.up("md")]: {
          flex: "0 0 66.666%",
        },
        [theme.breakpoints.up("lg")]: {
          flex: "0 0 50%",
        },
        "& .review": {
          "& .name": {
            fontFamily: theme.bodyFont,
            fontSize: 16,
            color: "white",
            fontWeight: "bold",
            letterSpacing: "0.016em",
            lineHeight: 1,
            marginBottom: 6,
          },
          "& .feefo-product-stars-widget": {
            display: "inline-block",
            marginRight: "auto",
            "& .feefowidget-product-stars__reviews-count": {
              display: "none",
            },
          },
          "& .date": {
            fontFamily: theme.bodyFont,
            fontSize: 12,
            color: "white",
            fontWeight: "bold",
            letterSpacing: "0.152em",
            display: "inline-block",
            marginLeft: "auto",
            textTransform: "uppercase",
            marginBottom: 0,
            marginTop: 0,
            float: "right",
          },
          "& .body": {
            fontFamily: theme.bodyFont,
            fontSize: 16,
            lineHeight: 2,
            color: "white",
            letterSpacing: "0.016em",
            display: "inline-block",
            marginLeft: "auto",
            marginBottom: 32,
          },
        },
      },
    },
  },
})

export default styles
