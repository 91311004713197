import React, { useEffect, useState } from "react"
import View3D from "@egjs/react-view3d"
import "@egjs/react-view3d/css/view3d-bundle.min.css"
import { makeStyles } from "@material-ui/styles"

export default function ModelViewer({ media, view3dRef }) {
  // const view3dRef = useRef(null)
  const [arAvailable, setArAvailable] = useState(false)
  const [modelLoaded, setModelLoaded] = useState(false)
  const useStyles = makeStyles(theme => {
    return {
      modelWrapper: {
        position: "relative",
        width: "100%",
        height: "auto",
      },
      view3d: {
        width: "100%",
        height: "auto",
        margin: "0 auto",
        aspectRatio: "1/1",
      },
      button: {
        appearance: "none",
        borderRadius: "9999px",
        width: "3.5rem",
        height: "3.5rem",
        boxShadow: "0 2px 16px rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--primary-navy)",
        backgroundColor: "var(--global-white)",
        border: "2px solid var(--primary-navy)",
        transition: "all 0.2s ease-in-out",

        "&:hover": {
          cursor: "pointer",
          color: "var(--global-white)",
          backgroundColor: "var(--primary-navy)",
        },
      },
      arButton: {
        position: "absolute",
        bottom: "1rem",
        right: "1rem",
        fontSize: "0.9rem",
        width: "auto",
        gap: "0.5rem",
        padding: "0rem 1rem",
        fontWeight: "600",
      },
      loadOverlay: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0.5rem",
        padding: "1rem",
        fontWeight: "600",
      },
    }
  })
  const classes = useStyles()

  useEffect(() => {
    // Check AR availability
    if (!arAvailable) {
      const checkArAvailability = async () => {
        const isAvailable = await view3dRef?.current?.ar?.isAvailable()
        setArAvailable(isAvailable)
      }

      checkArAvailability()
    }
  })

  return (
    <div className={classes.modelWrapper}>
      <View3D
        ref={view3dRef}
        tag="figure"
        className={classes.view3d}
        src={media?.mediaItemGlb}
        iosSrc={media?.mediaItemUsdz}
        poster={media?.mediaItemPreview}
        webAR={true}
        autoInit={true}
        onReady={() => {
          setModelLoaded(true)
        }}
      />
      {!modelLoaded ? (
        <div className={classes.loadOverlay}>
          <button
            className={`${classes.button}`}
            onClick={() => {
              view3dRef?.current.init()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M16.466 7.5C15.643 4.237 13.952 2 12 2 9.239 2 7 6.477 7 12s2.239 10 5 10c.342 0 .677-.069 1-.2" />
              <path d="m15.194 13.707 3.814 1.86-1.86 3.814" />
              <path d="M19 15.57c-1.804.885-4.274 1.43-7 1.43-5.523 0-10-2.239-10-5s4.477-5 10-5c4.838 0 8.873 1.718 9.8 4" />
            </svg>
          </button>
          <p>Load 3D View</p>
        </div>
      ) : null}
      {/* <div
        style={{
          margin: "1rem auto",
          padding: "1rem",
          display: "flex",
          gap: "0.5rem",
        }}
      >
        {!arAvailable && (
          <button
            className={`${classes.button} ${classes.arButton}`}
            onClick={() => {
              view3dRef?.current.init()
              view3dRef?.current.ar.enter()
            }}
          >
            <span>View in your space</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              enableBackground="new 0 0 24 24"
              viewBox="0 0 24 24"
              width="24"
              height="24"
            >
              <g>
                <rect fill="none" height="24" width="24" x="0" y="0"></rect>
              </g>
              <g>
                <g fill="currentColor">
                  <path d="M3,4c0-0.55,0.45-1,1-1h2V1H4C2.34,1,1,2.34,1,4v2h2V4z"></path>
                  <path d="M3,20v-2H1v2c0,1.66,1.34,3,3,3h2v-2H4C3.45,21,3,20.55,3,20z"></path>
                  <path d="M20,1h-2v2h2c0.55,0,1,0.45,1,1v2h2V4C23,2.34,21.66,1,20,1z"></path>
                  <path d="M21,20c0,0.55-0.45,1-1,1h-2v2h2c1.66,0,3-1.34,3-3v-2h-2V20z"></path>
                  <path d="M19,14.87V9.13c0-0.72-0.38-1.38-1-1.73l-5-2.88c-0.31-0.18-0.65-0.27-1-0.27s-0.69,0.09-1,0.27L6,7.39 C5.38,7.75,5,8.41,5,9.13v5.74c0,0.72,0.38,1.38,1,1.73l5,2.88c0.31,0.18,0.65,0.27,1,0.27s0.69-0.09,1-0.27l5-2.88 C18.62,16.25,19,15.59,19,14.87z M11,17.17l-4-2.3v-4.63l4,2.33V17.17z M12,10.84L8.04,8.53L12,6.25l3.96,2.28L12,10.84z M17,14.87l-4,2.3v-4.6l4-2.33V14.87z"></path>
                </g>
              </g>
            </svg>
          </button>
        )}
      </div> */}
    </div>
  )
}

export function ModelViewerPreview({ media }) {
  const useStyles = makeStyles(() => {
    return {
      wrapper: {
        position: "relative",
        width: "100%",
        height: "auto",
        backgroundColor: "var(--global-white)",

        "&:hover": {
          cursor: "pointer",

          "& $icon": {
            color: "var(--global-white)",
            backgroundColor: "var(--primary-navy)",
          },
        },
      },
      image: {
        width: "100%",
        height: "100%",
        aspectRatio: "1/1",
        objectFit: "cover",
        filter: "blur(2px)",
        opacity: 0.8,
      },
      icon: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        zIndex: 1,
        appearance: "none",
        borderRadius: "9999px",
        width: "2.5rem",
        height: "2.5rem",
        boxShadow: "0 2px 16px rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--primary-navy)",
        backgroundColor: "var(--global-white)",
        border: "2px solid var(--primary-navy)",
        transition: "all 0.2s ease-in-out",
      },
    }
  })
  const classes = useStyles()

  return (
    <div className={classes.wrapper}>
      <img
        className={classes.image}
        src={media?.mediaItemPreview}
        alt={media?.altText}
        loading="lazy"
      />
      <div className={classes.icon}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M16.466 7.5C15.643 4.237 13.952 2 12 2 9.239 2 7 6.477 7 12s2.239 10 5 10c.342 0 .677-.069 1-.2" />
          <path d="m15.194 13.707 3.814 1.86-1.86 3.814" />
          <path d="M19 15.57c-1.804.885-4.274 1.43-7 1.43-5.523 0-10-2.239-10-5s4.477-5 10-5c4.838 0 8.873 1.718 9.8 4" />
        </svg>
      </div>
    </div>
  )
}

export function ArView({ media, view3dRef }) {
  const [arAvailable, setArAvailable] = useState(false)
  const useStyles = makeStyles(theme => {
    return {
      wrapper: {
        margin: "0.5rem auto",
        padding: "1rem",
        display: "flex",
        justifyContent: "center",
        gap: "0.5rem",
      },
      button: {
        appearance: "none",
        borderRadius: "9999px",
        width: "3.5rem",
        height: "3.5rem",
        boxShadow: "0 2px 16px rgba(0,0,0,0.1)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "var(--primary-navy)",
        backgroundColor: "var(--global-white)",
        border: "2px solid var(--primary-navy)",
        transition: "all 0.2s ease-in-out",

        "&:hover": {
          cursor: "pointer",
          color: "var(--global-white)",
          backgroundColor: "var(--primary-navy)",
        },
      },
      arButton: {
        fontSize: "0.9rem",
        width: "auto",
        gap: "0.5rem",
        padding: "0rem 1rem",
        fontWeight: "600",
      },
    }
  })
  const classes = useStyles()

  useEffect(() => {
    // Check AR availability
    const checkArAvailability = async () => {
      const isAvailable = await view3dRef?.current?.ar?.isAvailable()
      setArAvailable(isAvailable)
    }

    checkArAvailability()
  })

  return (
    <div className={classes.wrapper}>
      {arAvailable && (
        <button
          className={`${classes.button} ${classes.arButton}`}
          onClick={() => {
            view3dRef?.current.ar.enter()
          }}
        >
          <span>View in your space</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <g>
              <rect fill="none" height="24" width="24" x="0" y="0"></rect>
            </g>
            <g>
              <g fill="currentColor">
                <path d="M3,4c0-0.55,0.45-1,1-1h2V1H4C2.34,1,1,2.34,1,4v2h2V4z"></path>
                <path d="M3,20v-2H1v2c0,1.66,1.34,3,3,3h2v-2H4C3.45,21,3,20.55,3,20z"></path>
                <path d="M20,1h-2v2h2c0.55,0,1,0.45,1,1v2h2V4C23,2.34,21.66,1,20,1z"></path>
                <path d="M21,20c0,0.55-0.45,1-1,1h-2v2h2c1.66,0,3-1.34,3-3v-2h-2V20z"></path>
                <path d="M19,14.87V9.13c0-0.72-0.38-1.38-1-1.73l-5-2.88c-0.31-0.18-0.65-0.27-1-0.27s-0.69,0.09-1,0.27L6,7.39 C5.38,7.75,5,8.41,5,9.13v5.74c0,0.72,0.38,1.38,1,1.73l5,2.88c0.31,0.18,0.65,0.27,1,0.27s0.69-0.09,1-0.27l5-2.88 C18.62,16.25,19,15.59,19,14.87z M11,17.17l-4-2.3v-4.63l4,2.33V17.17z M12,10.84L8.04,8.53L12,6.25l3.96,2.28L12,10.84z M17,14.87l-4,2.3v-4.6l4-2.33V14.87z"></path>
              </g>
            </g>
          </svg>
        </button>
      )}
    </div>
  )
}
