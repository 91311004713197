import React, { useState, useEffect } from "react"
import { makeStyles } from "@material-ui/styles"
import { useStaticQuery, graphql } from "gatsby"
import axios from "axios"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Buffer } from "buffer"

import ProductInfoAndSpec from "./ProductInfoAndSpec"
import ProductImageSlider from "./ProductImageSlider"
import ProductVariants from "./ProductVariants"
import RelatedProductSlider from "./RelatedProductSlider"

import styles from "./styles"

import { AcfAdvertBlock } from "../blocks/AcfAdvertBlock"
import useAllProducts from "../../hooks/use-all-products"
// import { AcfCalloutCtaBlock } from "../blocks/AcfCalloutCtaBlock"

const useStyles = makeStyles(theme => styles(theme))

function ProductPage({ categories, productData, productMeta, params }) {
  const classes = useStyles()

  const [products, setProducts] = useState("related")
  const [variant, setVariant] = useState()
  const [descriptionTab, setDescriptionTab] = useState(0)
  const [recommendations, setRecommendations] = useState([])

  const { productIdBase64 } = productData

  const allProducts = useAllProducts()

  useEffect(() => {
    const param = Number(new URLSearchParams(params).get("tab"))
    params && setDescriptionTab(param)
    if (param > 0) {
      setTimeout(() => {
        scrollTo(".info-and-spec")
      }, 500)
    }

    axios({
      method: "post",
      url: `${process.env.GATSBY_BACKEND_URL}/wp-json/brewshopify/v1/recommendations`,
      data: { productId: productIdBase64 },
    }).then(res => {
      if (res.data.data) {
        setRecommendations(res.data.data.product)
      }
    })
  }, []) // eslint-disable-line

  const data = useStaticQuery(graphql`
    query productPage {
      wp {
        themeOptions {
          ThemeOptions {
            productPageAdvertBlock {
              ... on WpAdvert {
                Advert {
                  title
                  body
                  ctaText
                  ctaUrl
                  image {
                    sourceUrl
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            productPageCalloutCta {
              ctaLink {
                url
                title
                target
              }
              textContent
              backgroundImage {
                localFile {
                  childImageSharp {
                    original {
                      src
                    }
                  }
                }
              }
            }
            productB2bNotPurchasable {
              textContent
              title
              ctaLink {
                target
                title
                url
              }
            }
            productB2bOutOfStock {
              textContent
              title
              ctaLink {
                target
                title
                url
              }
            }
            productB2cNotPurchasable {
              textContent
              title
              ctaLink {
                target
                title
                url
              }
            }
            productB2cOutOfStock {
              textContent
              title
              ctaLink {
                target
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const recommendedProducts = allProducts?.filter(product => {
    return recommendations
      ?.map(recommendation => Buffer.from(recommendation.id).toString("base64"))
      ?.includes(product.ProductData.productIdBase64)
  })

  return (
    <div className={classes.product}>
      <div className="wrapper">
        <ProductImageSlider
          images={productData.images}
          title={productData.title}
          media={productData?.media}
          variant={variant}
        />

        {productData.variants && (
          <ProductVariants
            productData={productData}
            productMeta={productMeta}
            setVariant={setVariant}
            setDescriptionTab={setDescriptionTab}
            productB2bNotPurchasable={
              data.wp.themeOptions.ThemeOptions.productB2bNotPurchasable
            }
            productB2bOutOfStock={
              data.wp.themeOptions.ThemeOptions.productB2bOutOfStock
            }
            productB2cNotPurchasable={
              data.wp.themeOptions.ThemeOptions.productB2cNotPurchasable
            }
            productB2cOutOfStock={
              data.wp.themeOptions.ThemeOptions.productB2cOutOfStock
            }
          />
        )}
      </div>

      {variant && (
        <ProductInfoAndSpec
          catStack={categories}
          productData={productData}
          productMeta={productMeta}
          contains={productMeta.containsProducts}
          sku={variant}
          descriptionTab={descriptionTab}
          setDescriptionTab={setDescriptionTab}
        />
      )}

      {(productMeta.matchingAccessories ||
        productMeta.matchingItems ||
        productMeta.relatedProducts) && <h2>Selected just for you</h2>}

      {!productMeta.matchingAccessories &&
        !productMeta.matchingItems &&
        !productMeta.relatedProducts && <div style={{ height: 50 }} />}

      {(productMeta.matchingAccessories ||
        productMeta.matchingItems ||
        recommendations?.length > 0) && (
        <div className="wrapper slider-controls">
          <div className="buttons">
            <button
              onClick={() => {
                setProducts("related")
              }}
              data-active={products === "related" ? true : false}
            >
              You may also like
            </button>
            {productMeta.matchingAccessories && (
              <button
                onClick={() => {
                  setProducts("accessories")
                }}
                data-active={products === "accessories" ? true : false}
              >
                Matching accessories
              </button>
            )}
            {productMeta.matchingItems && (
              <button
                onClick={() => {
                  setProducts("matching")
                }}
                data-active={products === "matching" ? true : false}
              >
                More from this range
              </button>
            )}
            {recommendations && (
              <button
                onClick={() => {
                  setProducts("recommended")
                }}
                data-active={products === "recommended" ? true : false}
              >
                People also bought
              </button>
            )}
          </div>
        </div>
      )}

      {productMeta.relatedProducts && products === "related" && (
        <RelatedProductSlider products={productMeta.relatedProducts} />
      )}
      {productMeta.matchingAccessories && products === "accessories" && (
        <RelatedProductSlider products={productMeta.matchingAccessories} />
      )}
      {productMeta.matchingItems && products === "matching" && (
        <RelatedProductSlider products={productMeta.matchingItems} />
      )}
      {recommendations?.length > 0 && products === "recommended" && (
        <RelatedProductSlider products={recommendedProducts} />
      )}

      {data.wp.themeOptions.ThemeOptions.productPageAdvertBlock !== null && (
        <>
          <AcfAdvertBlock
            attributes={{
              className: "",
              name: "acf/advert",
            }}
            block={{
              attributes: {
                className: "",
                name: "acf/advert",
              },
              blockAdvert: {
                advert:
                  data.wp.themeOptions.ThemeOptions.productPageAdvertBlock,
              },
              innerBlocks: [],
            }}
            innerBlocks={[]}
          />
          <br />
          <br />
        </>
      )}
    </div>
  )
}

export default ProductPage
