import React from "react"

export default function AddToBasket({ addProductsToCartHandler, disabled }) {
  return (
    <button
      className={`add-button ${disabled ? "disabled" : ""}`}
      onClick={() => addProductsToCartHandler()}
    >
      Add to basket
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.002"
        height="20.514"
        viewBox="0 0 22.002 20.514"
      >
        <path
          id="Basket-Icon"
          d="M23154.82,20896a2.759,2.759,0,0,1-2.617-2l-2.449-8.07a1.79,1.79,0,0,1-1.756-1.762v-.324a2.111,2.111,0,0,1,2.064-2.156h4.2l2.752-5.758a.7.7,0,0,1,.58-.437.671.671,0,0,1,.641.336.737.737,0,0,1,0,.75l-2.42,5.039h6.369l-2.436-5.078a.731.731,0,0,1,.322-.914.668.668,0,0,1,.9.27l2.75,5.723h4.215a2.111,2.111,0,0,1,2.063,2.156v.32a1.794,1.794,0,0,1-1.752,1.836l-2.449,8.07a2.767,2.767,0,0,1-2.619,2Zm8.359-1.441a1.385,1.385,0,0,0,1.32-1l2.439-8.07a1.392,1.392,0,0,1,1.309-1,.375.375,0,0,0,.268-.117.38.38,0,0,0,.109-.281v-.32a.707.707,0,0,0-.687-.719h-3.527l.529,1.129a1.064,1.064,0,0,1,.248.641.043.043,0,0,0,0,.031,1.082,1.082,0,0,1-.746,1.039,1.012,1.012,0,0,1-1.158-.465,1.109,1.109,0,0,1,.105-1.289l-.516-1.086h-7.744l-.506,1.051a1.115,1.115,0,0,1,.1,1.289,1,1,0,0,1-1.158.461,1.068,1.068,0,0,1-.746-1.031.066.066,0,0,0,0-.039,1.1,1.1,0,0,1,.248-.641l.525-1.09h-3.529a.7.7,0,0,0-.687.719v.32a.41.41,0,0,0,.105.281.385.385,0,0,0,.273.117,1.382,1.382,0,0,1,1.3,1l2.443,8.07a1.382,1.382,0,0,0,1.32,1Zm-1.65-2.156a.724.724,0,0,1-.414-.375.779.779,0,0,1-.02-.574l1.375-4.32a.708.708,0,0,1,.35-.41.635.635,0,0,1,.525-.039.7.7,0,0,1,.4.363.727.727,0,0,1,.031.547l-1.375,4.32a.691.691,0,0,1-.652.488.5.5,0,0,1-.107.008A.534.534,0,0,1,23161.529,20892.4Zm-5.279,0a.685.685,0,0,1-.619-.488l-1.373-4.32a.721.721,0,0,1,.432-.91.679.679,0,0,1,.871.449l1.377,4.32a.737.737,0,0,1-.033.582.692.692,0,0,1-.434.367.553.553,0,0,1-.113.008A.489.489,0,0,1,23156.25,20892.4Zm2.063-.719v-4.32a.688.688,0,1,1,1.375,0v4.32a.688.688,0,1,1-1.375,0Z"
          transform="translate(-23147.998 -20875.486)"
          fill="#00313c"
        />
      </svg>
    </button>
  )
}
