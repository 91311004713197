import React from "react"
import { Link } from "gatsby"

export default function ContactUs({ title, text, cta }) {
  return (
    <div className="find-stockist">
      <h4>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="19.383"
          height="19.383"
          viewBox="0 0 19.383 19.383"
        >
          <path
            id="info-circle"
            d="M11.691,2a9.691,9.691,0,1,0,9.691,9.691A9.691,9.691,0,0,0,11.691,2Zm0,17.445a7.753,7.753,0,1,1,7.753-7.753A7.753,7.753,0,0,1,11.691,19.445Zm0-8.238a.969.969,0,0,0-.969.969v2.907a.969.969,0,1,0,1.938,0V12.176A.969.969,0,0,0,11.691,11.207Zm0-3.877A1.211,1.211,0,1,0,12.9,8.542,1.211,1.211,0,0,0,11.691,7.33Z"
            transform="translate(-2 -2)"
            fill="#4b3048"
          />
        </svg>
        {title}
      </h4>
      <p>{text}</p>
      {cta && cta.title && cta.url && <Link to={cta.url}>{cta.title}</Link>}
    </div>
  )
}
