import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { makeStyles } from "@material-ui/styles"
import Cookies from "js-cookie"
import FavoriteIcon from "@material-ui/icons/Favorite"
import { Link } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import ReactHtmlParser from "react-html-parser"

import {
  addProductToWishlist,
  removeProductFromWishlist,
  createCartHandler,
  refreshCart,
} from "../../../app/actions"
import { addProductsToCart } from "../../../services/shopify"
import { isLoggedIn } from "../../../services/auth"
import { isBrowser } from "../../../helpers"

import Availability from "./Availability"
import Prices from "./Prices"
import Perks from "./Perks"
import styles from "./styles"
import AddToBasket from "./AddToBasket"
import Quantity from "./Quantity"
import StatusMessages from "./StatusMessages"
import ContactUs from "./ContactUs"
import axios from "axios"

const mapStateToProps = state => ({
  wishlist: state.WishlistSettings,
  cartId: state.CartSettings.cartId,
  products: state.CartSettings.products,
})

const mapDispatchToProps = dispatch => ({
  createCart: () => dispatch(createCartHandler()),
  addProductToWishlist: item => dispatch(addProductToWishlist(item)),
  removeProductFromWishlist: item => dispatch(removeProductFromWishlist(item)),
  refreshCart: () => dispatch(refreshCart()),
})

const connector = connect(mapStateToProps, mapDispatchToProps)
const useStyles = makeStyles(theme => styles(theme))

function ProductVariants({
  productData,
  productMeta,
  cartId,
  wishlist,
  addProductToWishlist,
  removeProductFromWishlist,
  setVariant,
  setDescriptionTab,
  createCart,
  refreshCart,
  productB2bNotPurchasable,
  productB2bOutOfStock,
  productB2cNotPurchasable,
  productB2cOutOfStock,
}) {
  const classes = useStyles()

  const [quantity, setQuantity] = useState(1)
  const [option1, setOption1] = useState(productData.variants[0]["option1"])
  const [option2, setOption2] = useState(productData.variants[0]["option2"])
  const [option3, setOption3] = useState(productData.variants[0]["option3"])
  const [price, setPrice] = useState(
    productData.variants[0].price
      ? productData.variants[0].price
      : productData.price
  )
  const [comparePrice, setComparePrice] = useState(
    productData.variants[0].comparePrice
      ? productData.variants[0].comparePrice
      : productData.comparePrice
  )
  const [status, setStatus] = useState(undefined)
  const [authenticated, setAuthentication] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const [shopifyProductData, setShopifyProductData] = useState()
  let t = undefined

  const filteredOutVariants2 = productData.variants
    .map(variant =>
      productData.variants
        .filter(v1 => v1["option1"] === option1)
        .filter(v => v["option1"] === variant["option1"])
    )
    .flat()
    .map(v => v["option2"])
    .filter((item, index, arr) => arr.indexOf(item) === index)

  const filteredOutVariants3 = productData.variants
    .map(variant =>
      productData.variants
        .filter(v1 => v1["option1"] === option1)
        .filter(v => v["option1"] === variant["option1"])
        .filter(v => v["option2"] === option2)
        .filter(v => v["option2"] === variant["option2"])
    )
    .flat()
    .map(v => v["option3"])
    .filter((item, index, arr) => arr.indexOf(item) === index)

  const findItemByVariants = productData.variants.find(
    product =>
      product.option1 === option1 &&
      product.option2 === option2 &&
      product.option3 === option3
  )

  const incrementQuantity = e => {
    e.preventDefault()
    setQuantity(quantity + 1)
  }

  const decrementQuantity = e => {
    e.preventDefault()
    setQuantity(quantity - 1)
  }

  const addProductToWishlistHandler = code => {
    if (wishlist.map(items => items.sku).includes(String(code))) {
      removeProductFromWishlist(String(code))
      setStatus("wishlist-remove-success")
    } else {
      addProductToWishlist({
        sku: String(code),
        quantity,
      })
      setStatus("wishlist-success")
    }
  }

  const setOption1Handler = option => {
    setOption1(option)
  }
  const setOption2Handler = option => {
    setOption2(option)
    setOption3(filteredOutVariants3[0])
    setPrice(findItemByVariants.price)
    setComparePrice(findItemByVariants.comparePrice)
  }
  const setOption3Handler = option => {
    setOption3(option)
    setPrice(findItemByVariants.price)
    setComparePrice(findItemByVariants.comparePrice)
  }

  const addProductsToCartHandler = async () => {
    const selectedVariant = findItemByVariants
    if (!selectedVariant) return

    clearTimeout(t)

    if (status === "processing") return

    setStatus("processing")

    if (!cartId) {
      const cart = await createCart()

      addProductsToCart({
        cartId: cart.cartId,
        products: [
          {
            quantity: Number(quantity),
            merchandiseId: selectedVariant.variantIdBase64,
          },
        ],
      })
        .then(res => {
          setStatus("cart-success")
          refreshCart()
        })
        .catch(err => {
          setStatus("cart-error")
        })
    } else {
      addProductsToCart({
        cartId,
        products: [
          {
            quantity: Number(quantity),
            merchandiseId: selectedVariant.variantIdBase64,
          },
        ],
      })
        .then(res => {
          setStatus("cart-success")
          refreshCart()
        })
        .catch(err => {
          setStatus("cart-error")
        })
    }
  }

  async function handleAuthentication() {
    const response = await isLoggedIn()

    if (response && response.data.errors) {
      setAuthentication(false)
    } else {
      setAuthentication(true)
    }
    setPageLoading(false)
  }

  useEffect(() => {
    setOption2(filteredOutVariants2[0])
    setOption3(filteredOutVariants3[0])
  }, [option1]) // eslint-disable-line

  useEffect(() => {
    setOption3(filteredOutVariants3[0])
  }, [option2]) // eslint-disable-line

  useEffect(() => {
    if (status === "processing") return
    if (status === undefined) return
    t = setTimeout(() => {
      setStatus(undefined)
    }, 6000)
  }, [status])

  useEffect(() => {
    Cookies.set("wishlist", JSON.stringify(wishlist), { expires: 365 })
  }, [wishlist])

  useEffect(() => {
    isBrowser && handleAuthentication()

    axios({
      method: "post",
      url: `${process.env.GATSBY_BACKEND_URL}/wp-json/brewshopify/v1/product`,
      data: {
        productId: productIdBase64,
      },
    })
      .then(res => res.data.data.product)
      .then(data => {
        setShopifyProductData(data)
      })
  }, [])

  useEffect(() => {
    findItemByVariants && setPrice(findItemByVariants.price)
    findItemByVariants && setComparePrice(findItemByVariants.comparePrice)
    setVariant(findItemByVariants.sku)
  }, [option1, option2, option3]) // eslint-disable-line

  const optionsNames =
    productData &&
    productData.options &&
    JSON.parse(productData.options).map(op => op.name)

  const variantSelected = findItemByVariants
  const inWishlist = wishlist.find(
    entry => entry.sku === String(variantSelected.sku)
  )

  const {
    availableToConsumers,
    availableToTrade,
    bestseller,
    discontinued,
  } = productMeta

  const { variants, productIdBase64 } = productData

  const isB2bProduct = availableToTrade
  const isB2cProduct = availableToConsumers
  const isB2bConsumer = authenticated
  const isB2cConsumer = !authenticated

  const inventoryFromShopify =
    shopifyProductData &&
    shopifyProductData.variants.edges.find(
      node => node.node.sku === variantSelected.sku
    )

  const inventoryQuantity = inventoryFromShopify
    ? inventoryFromShopify.node.inventoryQuantity
    : null

  const outOfStock = inventoryQuantity === 0

  const canBuy =
    (isB2cProduct && !authenticated) || (isB2bProduct && authenticated)

  function Message() {
    if (pageLoading) return null

    if (!canBuy && isB2cConsumer) {
      return (
        <ContactUs
          title={productB2cNotPurchasable.title}
          text={productB2cNotPurchasable.textContent}
          cta={productB2cNotPurchasable.ctaLink}
        />
      )
    }

    if (!canBuy && isB2bConsumer) {
      return (
        <ContactUs
          title={productB2bNotPurchasable.title}
          text={productB2bNotPurchasable.textContent}
          cta={productB2bNotPurchasable.ctaLink}
        />
      )
    }

    if (outOfStock && isB2cConsumer) {
      return (
        <ContactUs
          title={productB2cOutOfStock.title}
          text={productB2cOutOfStock.textContent}
          cta={productB2cOutOfStock.ctaLink}
        />
      )
    }

    if (outOfStock && isB2bConsumer) {
      return (
        <ContactUs
          title={productB2bOutOfStock.title}
          text={productB2bOutOfStock.textContent}
          cta={productB2bOutOfStock.ctaLink}
        />
      )
    }

    return null
  }

  return (
    <div className={classes.productVariants}>
      <div className="prod-data-top">
        <button
          className="review-button"
          onClick={() => {
            scrollTo(".info-and-spec")
            setDescriptionTab(3)
          }}
        >
          <img
            alt="Feefo logo"
            border="0"
            src={`https://api.feefo.com/api/logo?merchantidentifier=zest&template=Product-Stars-White-175x44.png&smallonzero=true&vendorref=${variantSelected.sku}`}
            title="Our customer Feefo rating"
          />
        </button>
        {bestseller && <div className="bestseller-tag">Best Seller</div>}
        <p className="sku">SKU: {variantSelected && variantSelected.sku}</p>
        <h1>{productData.title}</h1>
      </div>

      <Prices price={price} rprPrice={comparePrice} />

      <p className="variant-description">
        {ReactHtmlParser(productMeta.shortDescription)}
      </p>

      <Perks {...productMeta} setDescriptionTab={setDescriptionTab} />

      <div className="product-options">
        {optionsNames && (
          <div className="select-inputs">
            {optionsNames[0] && (
              <div>
                <label>{optionsNames[0]}</label>
                <select
                  value={option1}
                  onChange={e => setOption1Handler(e.target.value)}
                >
                  {variants &&
                    variants
                      .filter(
                        (item, index, arr) =>
                          arr
                            .map(variant => variant["option1"])
                            .indexOf(item["option1"]) === index
                      )
                      .map(variant => (
                        <option
                          key={variant["option1"]}
                          value={variant["option1"]}
                        >
                          {variant["option1"]}
                        </option>
                      ))}
                </select>
              </div>
            )}

            {optionsNames[1] && (
              <div>
                <label>{optionsNames[1]}</label>
                <select
                  value={option2}
                  onChange={e => setOption2Handler(e.target.value)}
                >
                  {filteredOutVariants2.map(variant => (
                    <option key={variant} value={variant}>
                      {variant}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {optionsNames[2] && (
              <div>
                <label>{optionsNames[2]}</label>
                <select
                  value={option3}
                  onChange={e => setOption3Handler(e.target.value)}
                >
                  {filteredOutVariants3.map(variant => (
                    <option key={variant} value={variant}>
                      {variant}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
        )}

        <div className="sku-and-stock">
          {!inventoryQuantity && !outOfStock && (
            <p className="processing">Checking stock</p>
          )}
          {canBuy && variantSelected && variantSelected.sku && !pageLoading && (
            <Availability
              quantity={inventoryQuantity}
              discontinued={discontinued}
            />
          )}
        </div>
      </div>

      <div className="cart-options">
        {canBuy && !outOfStock && (
          <>
            <Quantity
              quantity={quantity}
              decrementQuantity={decrementQuantity}
              incrementQuantity={incrementQuantity}
              setQuantity={setQuantity}
            />

            <AddToBasket
              addProductsToCartHandler={addProductsToCartHandler}
              disabled={status === "processing"}
            />
          </>
        )}

        <button
          className={inWishlist ? "wishlist added" : "wishlist"}
          onClick={() => addProductToWishlistHandler(variantSelected.sku)}
        >
          Add to Wish List
          <FavoriteIcon />
        </button>
      </div>

      <Message />

      <StatusMessages status={status} quantity={quantity} />

      {!authenticated && !outOfStock && !pageLoading && (
        <Link to="/find-a-retailer" className="find-store">
          Prefer to buy from a store?
        </Link>
      )}
    </div>
  )
}

export default connector(ProductVariants)
