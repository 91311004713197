import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

import key1 from "../../../images/key-fsc-cert.webp"
import pefc from "../../../images/key-pefc-cert.webp"
import key2 from "../../../images/key-warranty.webp"
import key3 from "../../../images/key-pressure-treated.webp"
import key4 from "../../../images/key-dip-treated.webp"
// import key5 from "../../../images/key-ready-built.png"
// import key6 from "../../../images/key-flat-packed.png"
import key7 from "../../../images/key-easy-build.webp"
import key8 from "../../../images/key-assembly-video.webp"
import key9 from "../../../images/key-delivery.webp"
import key10 from "../../../images/key-12mg.webp"
import key11 from "../../../images/key-made-in-britain.webp"

export default function Perks({
  assemblyDifficulty,
  warrantyPeriod,
  assemblyTime,
  preservativeTreatment,
  certification,
  delivery,
  madeInBritain,
  setDescriptionTab,
}) {
  return (
    <div className="key-breakdown">
      {assemblyDifficulty && assemblyDifficulty?.toLowerCase() === "easy" && (
        <div>
          <img
            width="24"
            height="24"
            src={key7}
            alt="Easy Build Product"
            loading="lazy"
          />
          <span>
            <strong>Easy Build Product</strong>
            Less components &amp; assembly stages
          </span>
        </div>
      )}
      {warrantyPeriod === "10 years" && (
        <div>
          <img
            width="24"
            height="24"
            src={key2}
            alt="10 Year Guarantee"
            loading="lazy"
          />
          <span>
            <strong>10 Year Guarantee</strong>
            10 year guarantee against rot
          </span>
        </div>
      )}
      {warrantyPeriod === "12 months" && (
        <div>
          <img
            width="24"
            height="24"
            src={key10}
            alt="12 Month Guarantee"
            loading="lazy"
          />
          <span>
            <strong>12 Month Guarantee</strong>
            12 month guarantee against rot
          </span>
        </div>
      )}
      {assemblyTime && (
        <div>
          <img
            width="24"
            height="24"
            src={key8}
            alt="Assembly"
            loading="lazy"
          />
          <span>
            <strong>Assembly</strong>
            <button
              onClick={() => {
                scrollTo(".info-and-spec")
                setDescriptionTab(2)
              }}
            >
              View instructions
            </button>
          </span>
        </div>
      )}
      {preservativeTreatment === "Pressure treated" && (
        <div>
          <img
            width="24"
            height="24"
            src={key3}
            alt="Pressure treated"
            loading="lazy"
          />
          <span>
            <strong>Pressure treated</strong>
            Longer lasting protection from rot
          </span>
        </div>
      )}
      {preservativeTreatment === "Dip treated" && (
        <div>
          <img
            width="24"
            height="24"
            src={key4}
            alt="Dip Treated"
            loading="lazy"
          />
          <span>
            <strong>Dip Treated</strong>
            Protection from rot and infestation
          </span>
        </div>
      )}
      {certification && certification === "FSC Certified" && (
        <div>
          <img
            width="24"
            height="24"
            src={key1}
            alt="FSC Certified"
            loading="lazy"
          />
          <span>
            <strong>
              FSC<sup>®</sup> Certified Product
            </strong>
            Members for over 15 years
          </span>
        </div>
      )}
      {certification && certification === "PEFC Certified" && (
        <div>
          <img
            width="24"
            height="24"
            src={pefc}
            alt="PEFC Certified"
            loading="lazy"
          />
          <span>
            <strong>PEFC Certified</strong>
            This product is from sustainably managed forests
          </span>
        </div>
      )}
      {delivery && (
        <div>
          <img
            width="24"
            height="24"
            src={key9}
            alt="Free delivery available"
            loading="lazy"
          />
          <span>
            <strong>Free delivery available</strong>
            {delivery}
          </span>
        </div>
      )}
      {madeInBritain && (
        <div>
          <img
            width="24"
            height="24"
            src={key11}
            alt="Made in Britain"
            loading="lazy"
          />
          <span>
            <strong>Made in Britain</strong>
            Proudly manufactured in Britain
          </span>
        </div>
      )}
    </div>
  )
}
